<template>
  <div>
    <p v-if="employersList" class="pogination__text">
      {{ currentPage }}-{{ Math.ceil(rows / perPage) }} из {{ filter.length }}
    </p>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      :hide-goto-end-buttons="true"
      :limit="1"
      class="pogination"
    >
      <template #prev-text>
        <img
          class="pagination__btn-icon pagination__btn-icon_prev"
          src="@/assets/png/database-icon-active.png"
          alt=""
        />
      </template>
      <template #next-text>
        <img class="pagination__btn-icon" src="@/assets/png/database-icon-active.png" alt="" />
      </template>
    </b-pagination>
    <div v-if="employers" class="table">
      <b-table
        id="my-table"
        class="table__box"
        :borderless="true"
        :items="filter"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        small
      >
        <template #cell(id)="data">
          <div>{{ data.index + 1 + (currentPage - 1) * perPage }}</div>
        </template>
        <template #cell(status)="data">
          <span class="table-btn__status" :class="{ 'table-btn__status-active': false }">
            {{ data.item.status }}
          </span>
        </template>
        <template #cell(name)="data"> {{ data.item.name }} {{ data.item.surname }} </template>
        <template #cell(action)="data">
          <div class="">
            <a class="table-btn">
              <b-icon
                icon="pencil-fill"
                aria-hidden="true"
                class="mr-2"
                variant="warning"
                @click="$router.push(`/add/new-manager?id=${data.item.id}`)"
              />
            </a>
            <a @click="deleteHandler(data.item.id)">
              <b-icon icon="trash-fill" aria-hidden="true" variant="danger" />
            </a>
          </div>
        </template>
        <template #cell(role)="data">
          <div>
            <p>{{ data.item.position ? data.item.position.title : "" }}</p>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      swowItem: null,
      perPage: 10,
      currentPage: 1,
      employers: null,
      fields: [
        {
          key: "id",
          label: "Номер",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-sm",
        },
        {
          key: "name",
          label: "ФИО",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "role",
          label: "Роль",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
          // 'saas'
          // variant: 'danger'
        },
        {
          key: "login",
          label: "Логин",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-md ",
        },
        {
          key: "password",
          label: "Пароль",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "action",
          label: "Действия",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-xs",
        },
      ],
      items: [],
    };
  },
  computed: {
    rows() {
      this.$emit("spinner");
      if (this.employersList) {
        return this.filter.length;
      }
      return this.items.length;
    },
    ...mapState(["employersList", "textFilter"]),
    filter() {
      return this.employersList.filter(
        (e) => e.name.toString().indexOf(this.textFilter || "") > -1
      );
    },
  },
  async created() {
    await this.$store.dispatch("getEmployersList");
    if (this.employersList) {
      this.employers = this.employersList.reverse();
    }
  },
  methods: {
    deleteHandler(city_id) {
      let ask = confirm("Are you sure you want to delete?");
      if (ask) {
        this.$api
          .post(`/web/delete-manager?employee_id=${city_id}`, {
            city_id,
            _method: "DELETE",
          })
          .then(() => {
            this.$toast.success("Успешно!");
            this.$store.dispatch("getCitiesProducts");
            this.$store.dispatch("getEmployersList");
          })
          .catch((e) => {
            this.$toast.error(e.response.data);
          });
      }
    },
  },
};
</script>

<style>
.table {
  width: 100%;
}
.pogination,
.pogination__text {
  position: absolute;
  top: -83px;
  right: -3px;
}
.pogination__text {
  right: 128px;
  top: -70px;
}
.page-item:nth-child(2) {
  display: none;
}
.page-item {
  margin-right: 18px;
  border: 1px solid #e0e9fa;
  border-radius: 8px;
}
.pagination__btn-icon_prev {
  transform: rotate(180deg);
}
</style>
